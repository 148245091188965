<template>
  <div class="grid min-h-screen w-full text-black form-container">
    <div class="wire-transfer-info">
      <div class="mt-6 sm:mt-8 lg:mt-0 mb-7">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table text-right">
                  <thead class="bg-default">
                    <tr>
                      <th scope="col" class="pe-2 text-start ps-2">Item</th>
                      <th scope="col" class="pe-2">Qty</th>
                      <!-- <th scope="col" class="pe-2" colspan="2">Rate</th> -->
                      <th scope="col" class="pe-2">Amount Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-start">{{ order.sku }}</td>
                      <td class="ps-4">{{ order.quantity }}</td>
                      <!-- <td class="ps-4" colspan="2">$ 9.00</td> -->
                      <td class="ps-4">
                        {{ format_price(order.originalPrice) }}
                      </td>
                    </tr>
                    <tr v-if="order.priceBreakdown.travelJewelryCosts">
                      <td class="text-start">Travel {{ order.jewelryType }}</td>
                      <td class="ps-4">1</td>
                      <td class="ps-4">
                        {{
                          format_price(order.priceBreakdown.travelJewelryCosts)
                        }}
                      </td>
                    </tr>
                    <tr v-if="order.engravings">
                      <td class="text-start">
                        <div>Engravings</div>
                        <div :style="{ fontFamily: order.engravings.font }">
                          {{ order.engravings.text }}
                        </div>
                      </td>
                      <td class="ps-4">1</td>
                      <td class="ps-4">
                        {{ format_price(50) }}
                      </td>
                    </tr>
                    <!-- <tr>
                          <td class="text-start">Soft UI Design System PRO</td>
                          <td class="ps-4">3</td>
                          <td class="ps-4" colspan="2">$ 100.00</td>
                          <td class="ps-4">$ 300.00</td>
                        </tr>
                        <tr>
                          <td class="text-start">Parts for service</td>
                          <td class="ps-4">1</td>
                          <td class="ps-4" colspan="2">$ 89.00</td>
                          <td class="ps-4">$ 89.00</td>
                        </tr> -->
                  </tbody>
                  <tfoot>
                    <tr>
                      <th></th>
                      <th class="h5 ps-4" colspan="1">Total</th>
                      <th colspan="1" class="text-right h5 ps-4">
                        {{ format_price(order.priceBreakdown.storeTotalCost) }}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form" @submit.prevent="">
        <EncryptedCheckUpload
          :iv="clientToken"
          @encrypted-check-data="handleUploadedCheck"
        />
        <div>
          <div style="padding: 20px; margin-top: 30px">
            <label tabindex="0">
              <input
                required
                v-model="ach.authorizedConfirmed"
                type="checkbox"
              />
              I authorize Luna Collection LLC to withdraw funds of the total
              amount of this transaction for the purpose of this order in
              accordance with the terms and conditions agreed upon. &nbsp;&nbsp;
              This authorization is to remain in force until the company
              receives the funds.</label
            >
          </div>
        </div>

        <div class="signature-Container">
          <VueSignaturePad
            :feedback="feedback"
            @save-signature="onSaveSignature"
          />
        </div>

        <soft-button
          v-if="ach.media"
          @click="onSubmit"
          style="height: 60px; width: 200px; border: 2px solid silver"
          color="success"
          variant="gradient"
        >
          <p>Submit</p>
        </soft-button>

        <!-- <soft-button
          color="danger"
          variant="gradient"
          @click="$emit('cancel')"
          type="button"
          name="button"
          >Cancel</soft-button
        > -->
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import SoftButton from "@/components/SoftButton.vue";
import VueSignaturePad from "@/components/SignatureCotainer.vue";
import EncryptedCheckUpload from "./EncryptedCheckUpload.vue";
import { ref, reactive } from "vue";

const props = defineProps(["clientToken", "order", "client", "bccEmails"]);
const emit = defineEmits(["finalize-order"]);
const showFeedback = ref(false);
const feedback = ref(null);
const ach = reactive({
  media: null,
  signature: null,
  authorizedConfirmed: false,
});

const onSubmit = async () => {
  if (validateForm()) finalizeOrder();
};

const handleUploadedCheck = (data) => {
  ach.media = {
    front: data.front,
    back: data.back,
  };
};

const finalizeOrder = async () => {
  emit("finalize-order", {
    paymentType: "Check",
    paymentStatus: "In Process",
    data: ach,
    key: "clientACHPaymentData",
  });
};

const validateForm = () => {
  let valid = true;
  for (const key in ach) {
    if (Object.hasOwnProperty.call(ach, key)) {
      const input = ach[key];
      if (!input) {
        if (key === "signature") {
          feedback.value = "Please Sign Before Continuing";
        }

        if (key === "media") {
          feedback.value =
            "Please Upload Front and Back of your check before continuing";
        }
        valid = false;
      }
    }
  }

  if (!valid) {
    alert("Must fill in all fields");
    setTimeout(() => {
      showFeedback.value = false;
      feedback.value = null;
    }, 3000);
  }

  return valid;
};

const onSaveSignature = (data) => {
  ach.signature = data;
};
</script>
  
  <style scoped>
.required {
  color: red;
}

.loading-container {
  font-family: Arial, sans-serif;
  display: inline-block;
}

.dot {
  margin-left: 3px;
  opacity: 0; /* Start the dots as invisible */
  animation: dotAppear 1.5s infinite; /* Apply the animation */
}

.loader-text {
  opacity: 0; /* Start the dots as invisible */
  animation: dotAppear 3s infinite; /* Apply the animation */
}

@keyframes dotAppear {
  0%,
  100% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
}

/* Stagger the animations */
.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

.signature-Container {
  margin-bottom: 20px;
  padding: 20px;
  height: 200px;
}
.bank-types {
  justify-content: space-between;
  align-items: baseline;
}

.options {
  display: flex;
  align-items: center;
}

.options > span {
  margin-right: 55px;
}
.wire-transfer-info {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  padding: 20px;
}

h2,
h3 {
  font-size: 20px;

  margin-bottom: 16px;
}

.info-block {
  margin-bottom: 10px;
  display: flex;
}

.info-block label {
  width: 150px;
  font-weight: bold;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  top: -5px;
  position: relative;
}

.info-block span {
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.dividers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 30px;
}

.cancel-btn {
  margin-bottom: 8%;
  opacity: 0.4;
  text-decoration: underline;
  font-size: 17px;
  text-transform: uppercase;
  cursor: pointer;
}

.divider {
  width: 45%;
  border-bottom: 1px solid silver;
}
</style>
  