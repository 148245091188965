<template>
  <div class="signature-container">
    <label style="font-size: 15px" for="">{{ props.label }}</label>
    <VueSignaturePad
      ref="signaturePad"
      class="signaturePad"
      :options="signaturePadOptions"
    />
    <p class="required" v-if="feedback">{{ feedback }}</p>
    <div class="footer">
      <soft-button
        color="warning"
        variant="gradient"
        @click="clear"
        type="button"
        name="button"
        >Clear</soft-button
      >
    </div>
  </div>
</template>
    
    <script setup>
import { ref } from "vue";
import SoftButton from "./SoftButton.vue";

const props = defineProps(["feedback", "label"]);
const emits = defineEmits("save-signature");

const signaturePad = ref(null);

const clear = () => {
  signaturePad.value.clearSignature();
};
const save = () => {
  const { isEmpty, data } = signaturePad.value.saveSignature();
  if (!isEmpty) {
    emits("save-signature", data);
  }
};
const onEnd = () => {
  save();
};
const signaturePadOptions = {
  minWidth: 1,
  maxWidth: 3,
  penColor: "black",
  onEnd,
};
</script>
    
  
  <style>
.required {
  color: red;
}

.signaturePad {
  background-color: rgba(115, 150, 159, 0.61);
}

.footer {
  width: 100%;
}

.footer > button {
  margin-top: 5px;
  float: right;
}

.signature-container {
  width: 100%;
  height: 100px;
}
</style>