<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div v-if="$store.state.authModule.isSuperAdmin">
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="success"
          variant="outline"
          @click="$router.push('/stores/new-store')"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-single-02"></i>
          </span>
          <span class="btn-inner--text"> New Store</span>
        </soft-button>
      </div>
      <div class="d-flex">
        <!-- <div class="dropdown d-inline">
          <soft-button
            id="navbarDropdownMenuLink2"
            color="dark"
            variant="outline"
            class="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Filters</soft-button
          >
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            style
          >
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Paid</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Refunded</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Canceled</a
              >
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
            <li>
              <a
                class="dropdown-item border-radius-md text-danger"
                href="javascript:;"
                >Remove Filter</a
              >
            </li>
          </ul>
        </div> -->
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="csv"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export CSV</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                @input="searchForStore"
                placeholder="Search By Store ID, Name or Company"
              >
              </soft-input>
            </div>
            <div class="table-wrapper">
              <table id="store-list" class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <!-- <th>Logo</th> -->
                    <th>Store Name</th>
                    <th>Company/Client Rep</th>
                    <th>Store ID</th>
                    <th>Collection</th>
                    <th>Store Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="store in queriedData"
                    :key="store.id"
                    class="table-row"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal"
                    @click="viewStoreDetails(store)"
                  >
                    <!-- <td>
                      <soft-avatar
                        v-if="store.metadata"
                        :img="store.metadata.url"
                        size="s"
                        class="me-0"
                        alt="user image"
                      />
                    </td> -->
                    <td class="text-xs font-weight-bold">
                      <span class="my-2 text-xs">{{ store.storeName }}</span>
                    </td>

                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>
                          {{ store.client.fullName }} -
                          {{ store.client.company }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ store.storeId }}
                        </p>
                      </div>
                    </td>

                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>
                          <!-- {{ store.client.collection.title }} -->
                          Luna Collection
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{
                            `${store.address.addressOne}, ${store.address.state}, ${store.address.city}`
                          }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pagination-wrapper">
              <div class="page-dropdown">
                <label for="orders">Per page: &nbsp; </label>
                <select v-model="pagination.perPage" name="orders">
                  <option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>

              <pagination
                :value="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :from="from"
                :to="to"
                @input="handlePagination"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    @close="(showModal = false), (selectedStore = {})"
    :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
    :showSaveBtn="false"
  >
    <template v-slot:title>
      <h2>Store Details</h2>
    </template>
    <template v-slot:body>
      <div class="store-details-wrapper">
        <StoreDetails @save-changes="saveChanges" :store="selectedStore" />
      </div>
    </template>
  </modal>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";

import {
  collection,
  doc,
  db,
  getDocs,
  getDoc,
  setDoc,
  query,
  where,
} from "@/config/firebaseInit.js";
import StoreDetails from "./StoreDetails.vue";
import { mapState } from "vuex";

export default {
  name: "OrderList",
  components: {
    SoftButton,
    SoftAvatar,
    SoftCheckbox,
    SoftInput,
    Modal,
    Pagination,
    StoreDetails,
  },
  data() {
    return {
      tableData: [],
      stores: [],
      showModal: false,
      selectedStore: {},
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
      currentSort: "name",
      currentSortOrder: "asc",
      filterBy: {
        client: "",
        status: "",
        customers: [],
        startDate: "",
        endDate: "",
      },
    };
  },
  computed: {
    ...mapState("authModule", {
      currentUser: (state) => {
        if (state.admin.id) {
          return state.admin;
        } else {
          return JSON.parse(window.localStorage.getItem("admin_data"));
        }
      },
      isSuperAdmin: (state) => {
        return state.isSuperAdmin;
      },
      isClient: (state) => {
        return state.isClient;
      },
      isStore: (state) => {
        return state.isStore;
      },
      isSalesRep: (state) => {
        return state.isSalesRep;
      },

      isEmployee: (state) => {
        return state.isEmployee;
      },
    }),
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  created() {
    if (this.isSuperAdmin || this.isEmployee || this.isClient) {
      this.fetchAllStores();
    } else {
      this.$router.push("/orders");
    }
  },
  methods: {
    async fetchAllStores() {
      let storeQuery = collection(db, "stores");
      if (this.$store.state.authModule.isClient) {
        storeQuery = query(
          collection(db, "stores"),
          where("client.id", "==", this.$store.state.authModule.admin.id)
        );
      }

      const stores = await getDocs(storeQuery);
      this.stores = stores.docs.map((user) =>
        Object.assign({ id: user.id }, user.data())
      );
      this.tableData = this.stores;

      // const promises = stores.docs.map(async (data) => {
      //   const store = Object.assign({ id: data.id }, data.data());
      //   const client = await getDoc(store.client);
      //   store.client = client.data();
      //   return  store;
      // });

      // Promise.all(promises).then(stores => {
      //   this.tableData = this.stores = stores;
      // })
    },
    handlePagination(value) {
      this.pagination.currentPage = value;
    },
    viewStoreDetails(store) {
      this.selectedStore = store;
      setTimeout(() => {
        this.showModal = true;
      }, 1000);
    },
    searchForStore(e) {
      if (!e.target.value) {
        this.tableData = this.store;
      }
      let input = e.target.value.toLowerCase();
      this.tableData = this.stores.filter(
        (store) =>
          store.id.toLowerCase().includes(input) ||
          store.client.company.toLowerCase().includes(input) ||
          store.storeName.toLowerCase().includes(input)
      );
    },
    async saveChanges(data, key) {
      let selectedStore = JSON.parse(JSON.stringify(this.selectedStore));
      if (key == "address") {
        selectedStore.address = data;
      } else {
        selectedStore = Object.assign(selectedStore, data);
      }

      const id = selectedStore.id;
      delete selectedStore.id;

      await setDoc(doc(db, "stores", id), selectedStore)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `${selectedStore.storeName} has been updated`,
            type: "success",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style>
.table-row {
  cursor: pointer;
}

.search-bar {
  position: relative;
  width: 200px;
  top: 10px;
  left: 10px;
}

.pagination-wrapper {
  padding: 20px;
}
</style>
