<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="">
        <form class action="index.html" method="post">
          <div class="card my-sm-5">
            <div class="card-header text-center">
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <img
                    class="mb-4 w-45"
                    src="@/assets/img/Luna-Collection.png"
                    alt="Logo"
                  />
                  <h6>Luna Collection LLC</h6>
                  <!-- <p class="d-block text-secondary">tel: +4 (074) 1090873</p> -->
                </div>
              </div>
              <br />
              <div class="col-lg-12 col-md-7 text-md-end text-end mt-5">
                <h6 class="d-block mt-2 mb-0">
                  Billed to: Luna Collection LLC
                </h6>
                <p class="text-secondary">
                  <!-- 4006 Locust View Drive
                  <br />San Francisco CA <br />California -->
                </p>
              </div>
              <div class="row justify-content-md-between">
                <div class="col-md-4 mt-auto">
                  <h6 class="mb-0 text-start text-secondary">
                    Purchase Order no
                  </h6>
                  <h5 class="text-start mb-0">
                    #{{ order.purchaseOrderNumber }}
                  </h5>
                </div>
                <div class="col-lg-5 col-md-7 mt-auto">
                  <div class="row mt-md-5 mt-4 text-md-end text-start">
                    <div class="col-md-6">
                      <h6 class="text-secondary mb-0">Invoice date:</h6>
                    </div>
                    <div v-if="order.createdAt" class="col-md-6">
                      <h6 class="text-dark mb-0">
                        {{ format_date(order.createdAt) }}
                      </h6>
                    </div>
                  </div>
                  <!-- <div class="row text-md-end text-start">
                    <div class="col-md-6">
                      <h6 class="text-secondary mb-0">Due date:</h6>
                    </div>
                    <div class="col-md-6">
                      <h6 class="text-dark mb-0">11/03/2019</h6>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table text-right">
                      <thead class="bg-default">
                        <tr>
                          <th scope="col" class="pe-2 text-start ps-2">Item</th>
                          <th scope="col" class="pe-2">Qty</th>
                          <!-- <th scope="col" class="pe-2" colspan="2">Rate</th> -->
                          <th scope="col" class="pe-2">Amount Due</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-start">{{ order.sku }}</td>
                          <td class="ps-4">{{ order.quantity }}</td>
                          <!-- <td class="ps-4" colspan="2">$ 9.00</td> -->
                          <td class="ps-4">
                            {{ format_price(order.originalPrice) }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            order.priceBreakdown &&
                            order.priceBreakdown.travelJewelryCosts
                          "
                        >
                          <td class="text-start">
                            Travel {{ order.jewelryType }}
                          </td>
                          <td class="ps-4">1</td>
                          <td class="ps-4">
                            {{
                              format_price(
                                order.priceBreakdown.travelJewelryCosts
                              )
                            }}
                          </td>
                        </tr>
                        <tr v-if="order.engravings">
                          <td class="text-start">
                            <div>Engravings</div>
                            <div :style="{fontFamily: order.engravings.font}">
                              {{ order.engravings.text }}
                            </div>
                          </td>
                          <td class="ps-4">1</td>
                          <td class="ps-4">
                            {{ format_price(50) }}
                          </td>
                        </tr>
                        <!-- <tr>
                          <td class="text-start">Soft UI Design System PRO</td>
                          <td class="ps-4">3</td>
                          <td class="ps-4" colspan="2">$ 100.00</td>
                          <td class="ps-4">$ 300.00</td>
                        </tr>
                        <tr>
                          <td class="text-start">Parts for service</td>
                          <td class="ps-4">1</td>
                          <td class="ps-4" colspan="2">$ 89.00</td>
                          <td class="ps-4">$ 89.00</td>
                        </tr> -->
                      </tbody>
                      <tfoot>
                        <tr>
                          <th></th>
                          <th class="h5 ps-4" colspan="1">Total</th>
                          <th colspan="1" class="text-right h5 ps-4">
                            {{ format_price(order.totalCost) }}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer mt-md-5 mt-4">
              <div class="row">
                <div class="col-lg-5 text-left">
                  <h5>Thank you!</h5>
                  <p class="text-secondary text-sm">
                    If you encounter any issues related to the invoice you can
                    contact us at:
                  </p>
                  <h6 class="text-secondary mb-0">
                    email:
                    <span class="text-dark">info@mylunacollection.com</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
  <script>
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "ClientInvoice",
  components: {
    SoftButton,
  },
  props: {
    order: {
      type: Object,
      default: Object,
    },
  },
};
</script>
  