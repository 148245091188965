<template>
  <div class="app_update_button_container">
    <button class="update-alert" v-if="updateAvailable" @click="refreshApp">
      <md-icon>update</md-icon>
      New update available! Click to update.
    </button>
  </div>
  <sidenav
    v-if="showSidenav"
    :custom-class="color"
    :class="[isTransparent, isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <!-- nav -->
    <navbar
      v-if="showNavbar"
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :text-white="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
      :disableNotifications="disableNotifications"
      @is-messenger-open="isMessengerOpen"
    />
    <router-view />
    <app-footer v-show="showFooter" />
    <div class="position-fixed top-1 end-1 z-index100">
      <soft-snackbar
        v-if="showNotification"
        :title="activeNotification.title"
        :date="format_date_time(activeNotification.createdAt)"
        :description="activeNotification.description"
        :icon="{ component: 'ni ni-check-bold', color: 'white' }"
        color="info"
        :close-handler="closeNotification"
      />
    </div>
  </main>
</template>
<script>
import Sidenav from "./components/Sidenav";
import Navbar from "@/components/Navbars/Navbar.vue";
import AppFooter from "@/components/Footer.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import SoftSnackbar from "@/components/SoftSnackbar.vue";
import { mapMutations, mapState } from "vuex";
import {
  collection,
  doc,
  db,
  query,
  updateDoc,
  onSnapshot,
  where,
} from "@/config/firebaseInit.js";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
    SoftSnackbar,
    SoftAlert,
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateAvailable: false,
      updateExists: false,
      showNotification: false,
      notifications: [],
      messageNotifications: [],
      activeNotification: {},
      disableNotifications: false,
    };
  },
  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "isNavFixed",
      "isAbsolute",
      "navbarFixed",
      "absolute",
      "color",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  watch: {
    activeNotification: {
      deep: true,
      handler() {
        if (
          this.activeNotification.type == "messenger" &&
          this.disableNotifications
        ) {
          this.closeNotification();
        } else {
          this.showNotification = true;
        }
      },
    },
    "$store.state.authModule.admin": {
      deep: true,
      handler() {
        this.listenToNotifications();
      },
    },
  },
  beforeMount() {
    document.addEventListener("swUpdate", this.showUIUpdate, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // To prevent infinite loop refresh
        if (this.refreshing) {
          return;
        }

        this.refreshing = true;
        window.location.reload();
      });
    }
    this.$store.state.isTransparent = "bg-transparent";
    this.$store.dispatch("authModule/getAdminData", null);
    // this.$store.dispatch("authModule/showMessengerAPP");
    this.$store.dispatch("authModule/getAdminUsers", null);
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    showUIUpdate(e) {
      console.log(e);
      console.log("UI has been activated and should show alert");
      this.registration = e.detail;
      this.updateAvailable = true;
      Swal.fire({
        title: "New Update!",
        text: "New update available! Click Update to refresh. Remember to save any changes you made before continuing",
        type: "info",
        showCancelButton: true,
        confirmButtonText: "UPDATE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          this.refreshApp();
        } else if (result.isDismissed) {
          if (this.updateExists)
            setTimeout(() => {
              this.showUIUpdate(e);
            }, 300000);
        }
      });
    },
    closeNotification() {
      updateDoc(
        doc(
          db,
          "users",
          this.$store.state.authModule.admin.id,
          "notifications",
          this.activeNotification.id
        ),
        {
          active: false,
        }
      ).then(() => {
        this.showNotification = false;
      });
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    async listenToNotifications() {
      const notificationRef = collection(
        db,
        "users",
        this.$store.state.authModule.admin.id,
        "notifications"
      );
      const notificationsQuery = query(
        notificationRef,
        where("active", "==", true)
        // orderBy("createdAt", "desc")
      );

      const messageNotificationsQuery = query(
        notificationRef,
        where("active", "==", true),
        where("type", "==", "messenger")
        // orderBy("createdAt", "desc")
      );

      const unsub = await onSnapshot(notificationsQuery, (querySnapshot) => {
        this.notifications = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
        if (this.notifications.length) {
          this.activeNotification =
            this.notifications[this.notifications.length - 1];
        }
      });
    },
    isMessengerOpen(disable) {
      this.disableNotifications = disable;
    },
  },
};
</script>
<style scoped>
.app_update_button_container {
  width: 100%;
  display: flex !important;
  justify-content: flex-end !important;
}
.update-alert {
  width: 100%;
  background-color: #25bf6e;
  border: none;
  padding: 10px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  font-family: "Roboto";
  z-index: 10;
  justify-content: center;
}

.update-alert img {
  height: 30px;
}

.update-alert p {
  margin: 0 0 0 20px;
  font-size: 18px;
  font-family: AzoSans;
}
</style>
