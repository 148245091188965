import { httpsCallable, functions } from "@/config/firebaseInit.js";

const { DateTime } = require("luxon");
const mixin = function () {
  return {
    methods: {
      capitalize(sentence) {
        return sentence.replace(/\b\w/g, (l) => l.toUpperCase());
      },
      key_to_text(key) {
        return key.replace("_", " ");
      },
      format_date(timestamp) {
        return DateTime.fromMillis(timestamp).toFormat("DD");
      },
      format_date_time(timestamp) {
        return DateTime.fromMillis(timestamp).toFormat("ff");
      },
      format_time(timestamp) {
        return DateTime.fromMillis(timestamp).toFormat("hh:mm a");
      },
      format_dow(timestamp) {
        return DateTime.fromMillis(timestamp).toFormat("dddd");
      },
      async sendEmailNoteNotificationToUsers(data) {
        const content = Object.assign({
          template_id: 8,
          csv: "",
          html: "",
          filename: "",
          subject: "",
          to: data.to,
          cc: data.cc,
          bcc: ["info@mylunacollection.com"],
          params: {
            userName: data.fullName,
            notificationType: data.type,
            message: data.message,
          },
        });
        let contents = [];
        contents.push(content);
        const sendEmails = httpsCallable(functions, "sendEmails");
        try {
          return await sendEmails({ contents });
        } catch (error) {
          console.debug(error);
          this.displayError(error);
        }
      },
      displayError(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log({ errorCode, errorMessage });
        return this.$swal(
          "Error!",
          `${errorMessage} - code: ${errorCode}. Please try again or contact support.`,
          "error"
        );
      },
      format_price(price) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(price);
      },
      handlePasswordValidation(password, errors) {
        let valid = true;
        let feedback = {};
        feedback.current = "";
        feedback.repeat = "";

        const regex = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[0-9])(?=.{6,})[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
        if (!password.current || !password.repeat) {
          errors.repeat = Boolean(!password.repeat);
          errors.current = Boolean(!password.current);
          valid = false;
        } else if (
          !regex.test(password.current) &&
          !regex.test(password.repeat)
        ) {
          feedback.current = "The password does not meet requirement";
          valid = false;
        } else if (password.current !== password.repeat) {
          feedback.repeat = "Passwords don't match";
          valid = false;
        }
        return { valid, feedback, errors };
      },
    },
  };
};

export default {
  mixin,
};
