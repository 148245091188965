<template>
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :id="id"
    tabindex="-1"
    aria-labelledby="ModalLabel"
    aria-hidden="true"
    ref="Modal"
  >
    <div class="modal-dialog">
      <div class="modal-content" :style="styleFormat">
        <div class="modal-header">
          <h5 class="modal-title" id="ModalLabel">
            <slot name="title"></slot>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            X
          </button>
        </div>
        <div class="modal-body">
          <slot class="modal-body" name="body"> </slot>
        </div>
        <div class="modal-footer">
          <slot class="modal-footer" name="footer"> </slot>
          <button
            v-if="showCloseBtn"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            Close
          </button>
          <!-- <button
            v-if="showSaveBtn"
            @click="$emit('click')"
            type="button"
            class="btn btn-primary"
          >
            Save changes
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "Modal",
    },
    showSaveBtn: {
      type: Boolean,
      default: true,
    },
    styleFormat: {
      type: Object,
      default: () => Object.assign({}),
    },
  },
  mounted() {},
  methods: {
    closeModal: function () {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *  
 * You can easily play with the modal transition by editing
 * these styles.
 */
// .close {
//   position: absolute;
//   right: 10px;
//   color: red;
//   cursor: pointer;
//   font-size: 20px;
// }
// .modal-enter {
//   opacity: 0;
// }

// .modal-leave-active {
//   opacity: 0;
// }

// .modal-enter .modal-container,
// .modal-leave-active .modal-container {
//   -webkit-transform: scale(1.1);
//   transform: scale(1.1);
// }
</style>
