<template>
  <section v-if="order" class="antialiased dark:bg-gray-900">
    <div class="mx-auto max-w-screen-xl px-4 2xl:px-0">
      <div v-if="cards.length">
        <h2>Saved Cards:</h2>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div
            class="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4 dark:border-gray-700 dark:bg-gray-800"
          >
            <div
              v-for="card in cards"
              :key="card.token"
              class="flex items-start"
              style="color: black; justify-content: space-between"
            >
              <input
                type="checkbox"
                :id="card.token"
                :value="card.token"
                @change="onCheckboxChange(card.token)"
              />
              <div class="ms-4 text-sm">
                <label
                  for="credit-card"
                  class="font-medium leading-none text-gray-900 dark:text-white"
                >
                  {{ card.cardType }} ending in {{ card.last4 }}
                </label>
                <p
                  id="pay-on-delivery-text"
                  class="mt-1 text-xs font-normal text-gray-500 dark:text-gray-400"
                >
                  EXP: {{ card.expirationMonth }} / {{ card.expirationYear }}
                </p>
              </div>
              <div class="card-image">
                <img
                  class="h-8 w-auto dark:hidden"
                  :src="`https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/${card.cardType.toLowerCase()}.svg`"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="cards.length" class="dividers">
        <div class="divider"></div>
        <div>OR</div>
        <div class="divider"></div>
      </div>
      <div class="mx-auto">
        <div class="_container">
          <form
            @submit.prevent=""
            id="cc-form"
            style="margin: 0 auto; border-radius: 15px"
            class="w-full rounded-lg border border-silver-800 p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6 lg:max-w-xl lg:p-8"
          >
            <div class="row">
              <div class="row">
                <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                  <label
                    for="full_name"
                    class="mb-2 block text-sm font-medium text-white-900 dark:text-white"
                  >
                    Full name (as displayed on card)*
                  </label>
                  <input
                    type="text"
                    id="full_name"
                    v-model="ccForm.fullName"
                    class="w-100 hosted-field block w-full border border-gray-300 bg-gray-50 text-lg text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                    placeholder="Jane Doe"
                    required
                  />
                </div>
                <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                  <label
                    for="card-number"
                    class="mb-2 block text-sm font-medium text-white-900 dark:text-white"
                  >
                    Card number*
                  </label>
                  <div
                    id="card-number"
                    class="hosted-field block w-full border border-gray-300 bg-gray-50 text-lg text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                  ></div>
                </div>
              </div>
              <div class="row">
                <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                  <label
                    for="expiration-date"
                    class="mb-2 block text-sm font-medium text-white-900 dark:text-white"
                  >
                    Card expiration*
                  </label>
                  <div
                    id="expiration-date"
                    class="hosted-field p-2.5 block w-full border border-gray-300 bg-gray-50 text-lg text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                  ></div>
                </div>

                <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                  <label
                    for="cvv"
                    class="mb-2 flex items-center gap-1 text-sm font-medium text-white-900 dark:text-white"
                  >
                    CVV*
                    <!-- <button
                      data-tooltip-target="cvv-desc"
                      data-tooltip-trigger="hover"
                      class="text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-white"
                    >
                      <svg
                        class="h-4 w-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button> -->
                    <div
                      id="cvv-desc"
                      role="tooltip"
                      class="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700"
                    >
                      The last 3 digits on back of card
                      <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </label>
                  <div
                    id="cvv"
                    class="hosted-field p-2.5 block w-full border border-gray-300 bg-gray-50 text-lg text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                  ></div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 gap-4 input-box">
              <input type="checkbox" v-model="saveCard" name="" id="" />
              <span> Save this card? </span>
            </div>

            <input
              type="hidden"
              id="nonce"
              name="payment_method_nonce"
              v-model="nonce"
            />
            <div class="logo-container">
              <!-- <img
                  class="logo"
                  src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal.svg"
                  alt=""
                />
                <img
                  class="hidden h-8 w-auto dark:flex"
                  src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal-dark.svg"
                  alt=""
                /> -->
              <img
                class="logo"
                src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa.svg"
                alt=""
              />
              <img
                class="hidden h-8 w-auto dark:flex"
                src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa-dark.svg"
                alt=""
              />
              <img
                class="logo"
                src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg"
                alt=""
              />

              <img
                class="logo"
                src="@/assets/img/logos/discover_card_icon.png"
                alt=""
              />
              <img
                class="logo"
                src="@/assets/img/logos/amex_card_icon.png"
                alt=""
              />
            </div>
          </form>

          <div style="padding: 20px" class="">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table text-right">
                    <thead class="bg-default">
                      <tr>
                        <th scope="col" class="pe-2 text-start ps-2">Item</th>
                        <th scope="col" class="pe-2">Qty</th>
                        <!-- <th scope="col" class="pe-2" colspan="2">Rate</th> -->
                        <th scope="col" class="pe-2">Amount Due</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-start">{{ order.sku }}</td>
                        <td class="ps-4">{{ order.quantity }}</td>
                        <!-- <td class="ps-4" colspan="2">$ 9.00</td> -->
                        <td class="ps-4">
                          {{ format_price(order.originalPrice) }}
                        </td>
                      </tr>
                      <tr v-if="order.priceBreakdown.travelJewelryCosts">
                        <td class="text-start">
                          Travel {{ order.jewelryType }}
                        </td>
                        <td class="ps-4">1</td>
                        <td class="ps-4">
                          {{
                            format_price(
                              order.priceBreakdown.travelJewelryCosts
                            )
                          }}
                        </td>
                      </tr>
                      <tr v-if="order.engravings">
                        <td class="text-start">
                          <div>Engravings</div>
                          <div :style="{ fontFamily: order.engravings.font }">
                            {{ order.engravings.text }}
                          </div>
                        </td>
                        <td class="ps-4">1</td>
                        <td class="ps-4">
                          {{ format_price(50) }}
                        </td>
                      </tr>
                      <!-- <tr>
                          <td class="text-start">Parts for service</td>
                          <td class="ps-4">1</td>
                          <td class="ps-4" colspan="2">$ 89.00</td>
                          <td class="ps-4">$ 89.00</td>
                        </tr> -->
                    </tbody>
                    <tfoot>
                      <tr>
                        <th></th>
                        <th class="h5 ps-4" colspan="1">CC Fee</th>
                        <th colspan="1" class="text-right h5 ps-2">
                          + {{ format_price(ccFee) }}
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th class="h5 ps-4" colspan="1">Total</th>
                        <th colspan="1" class="text-right h5 ps-4">
                          {{ format_price(totalCost) }}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <div class="btn-row">
              <soft-button
                class="w-100"
                @click="onSubmit"
                type="submit"
                color="info"
                variant="gradient"
              >
                <div v-if="orderBeingProcessed" class="loading-container">
                  <span class="loader-text">Order Processing</span>
                  <span class="dot">.</span><span class="dot">.</span
                  ><span class="dot">.</span>
                </div>
                <div v-if="!orderBeingProcessed">Finalize Order</div>
              </soft-button>
              <!-- <div @click="$emit('cancel')" class="cancel-btn">Cancel</div> -->
            </div>
          </div>
        </div>

        <p
          class="mt-2 text-center text-white-500 dark:text-white-400 sm:mt-8 lg:text-center"
        >
          Payment processed by
          <a
            href="https://www.braintreepayments.com/"
            title=""
            style="text-decoration: underline"
            class="font-medium text-primary-700 underline hover:no-underline dark:text-primary-500"
            >PayPal BrainTree</a
          >
          for
          <a
            href="#"
            title=""
            style="text-decoration: underline"
            class="font-medium text-primary-700 underline hover:no-underline dark:text-primary-500"
            >Luna Collection LLC</a
          >
          - United States Of America
        </p>
      </div>
    </div>
  </section>
</template>
  
  <script>
import axios from "axios";
import braintree from "braintree-web";
import SoftButton from "@/components/SoftButton.vue";
export default {
  components: {
    SoftButton,
  },
  props: {
    selectedOrder: {
      type: Object,
      default: Object,
    },
    clientToken: {
      type: String,
      default: "",
    },
    client: {
      type: Object,
      default: Object,
    },
    bccEmails: {
      type: Array,
      default: [],
    },
  },
  emits: ["finalize-order"],
  data() {
    return {
      selected: "Credit/Debit",
      hostedFieldsInstance: null,
      ccForm: {
        fullName: null,
        ccv: null,
        expDate: null,
        cardNum: null,
      },
      cards: [],
      ccFee: 0,
      deviceData: "",
      saveCard: false,
      orderBeingProcessed: null,
      selectedCardToken: null,
      nonce: "",
      order: null,
    };
  },
  computed: {
    totalCost() {
      const travelJewelryCosts =
        this.order.priceBreakdown.travelJewelryCosts || 0;
      const engravings = this.order.engravings ? 50 : 0;
      return (
        (this.order.originalPrice + travelJewelryCosts + engravings) * 1.03
      );
    },
  },
  async mounted() {
    this.order = JSON.parse(JSON.stringify(this.selectedOrder));
    setTimeout(() => {
      this.initializeBraintree();
      this.fetchSavedCards();
      this.addCCFee();
    }, 500);
  },

  methods: {
    addCCFee() {
      this.ccFee = this.order.originalPrice * 0.03;
      this.order.priceBreakdown.storeTotalCost += this.ccFee;
    },
    async onSubmit() {
      if (!this.hostedFieldsInstance) {
        console.error("Hosted fields instance not available");
        return;
      }
      this.orderBeingProcessed = true;
      if (this.selectedCardToken) {
        const res = await this.fetchVaultedCardPaymentNounce(
          this.selectedCardToken
        );
        return this.submitPayment(res.token, deviceData);
      }

      this.hostedFieldsInstance.tokenize(
        {
          cardholderName: this.order.client.fullName,
        },
        async (err, payload) => {
          if (err) {
            console.log(err);
            if (err.code == "HOSTED_FIELDS_FIELDS_EMPTY") {
              this.$swal("PLEASE FILL IN ALL FIELDS");
            }

            if (err.code == "HOSTED_FIELDS_FIELDS_INVALID") {
              this.$swal(err.message);
            }

            this.orderBeingProcessed = false;
            return;
          }

          if (this.saveCard) {
            this.client_token = await saveCardToVault(payload.nonce);
            this.client_token = this.client_token.paymentMethod.token;
            return submitPayment(this.client_token, this.deviceData);
          }

          return this.submitPayment(payload.nonce, deviceData);
        }
      );
    },

    async fetchVaultedCardPaymentNounce(token) {
      try {
        return await axios.post(
          "https://us-central1-luna-collection-ttc.cloudfunctions.net/LunaCollectionServer/retrieve_nounce_from_vault",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: {
              token,
            },
          }
        );
      } catch (error) {
        console.error("Failed to fetch saved cards:", error);
      }
    },

    async saveCardToVault(nonce) {
      let customerId = client.braintree_customer_id;
      return axios
        .post(
          "https://us-central1-luna-collection-ttc.cloudfunctions.net/LunaCollectionServer/save_card",
          //"https://us-central1-dis-management.cloudfunctions.net/LunaCollectionServer/save_card",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: {
              paymentMethodNonce: nonce,
              customerId,
            },
          }
        )
        .then((response) => response)
        .catch((error) => {
          console.error("Error saving card:", error);
        });
    },

    async fetchSavedCards() {
      let customerId = this.client.braintree_customer_id;
      if (!customerId) {
        customerId = await createBrainTreeCustomer();
      }
      try {
        // Fetch the customer ID from your authenticated user
        const response = await axios.get(
          `https://us-central1-luna-collection-ttc.cloudfunctions.net/LunaCollectionServer/fetch_customer/${customerId}`
          // `https://us-central1-dis-management.cloudfunctions.net/LunaCollectionServer/fetch_customer/${customerId}`
        );
        const customer = response.data;
        this.cards = customer.creditCards;
      } catch (error) {
        console.debug("Failed to fetch saved cards:", error);
        this.displayError(error);
      }
    },

    async createBrainTreeCustomer() {
      const { firstName, lastName, email, company, phone } = this.client;
      try {
        const response = await axios.post(
          `https://us-central1-luna-collection-ttc.cloudfunctions.net/LunaCollectionServer/create_customer`,
          // `https://us-central1-dis-management.cloudfunctions.net/LunaCollectionServer/create_customer`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: {
              firstName,
              lastName,
              email,
              company,
              phone,
            },
          }
        );

        if (response.success) return response.customerId;
      } catch (error) {
        console.error("Failed to fetch saved cards:", error);
      }
    },

    onCheckboxChange(option) {
      if (this.selectedCardToken === option) {
        this.selectedCardToken = ""; // This line allows toggling off a selected checkbox
      } else {
        this.selectedCardToken = option;
      }
    },
    submitPayment(token, deviceData) {
      const formData = new FormData();
      formData.append("full_name", ccForm.fullName);
      formData.append("payment_method_nonce", token);

      axios
        .post(
          "https://us-central1-luna-collection-ttc.cloudfunctions.net/LunaCollectionServer/checkout",
          //"https://us-central1-dis-management.cloudfunctions.net/LunaCollectionServer/checkout",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: {
              nonceFromTheClient: token,
              totalAmount: parseFloat(this.totalCost.toFixed(2)),
              // totalAmount: 1,
              deviceDataFromTheClient: deviceData,
              customerId: this.client.braintree_customer_id || "",
            },
          }
        )
        .then((response) => {
          const status = response.status || response.data.status;
          if (status == 200) {
            this.$emit("finalize-order", {
              paymentType: "Credit",
              paymentStatus: "Paid",
              data: null,
              key: null,
            });
          }

          if (status == 500 || status == 401) {
            this.$swal.fire(
              "Order can not be proccessed. If you tried to use a saved card try adding it manually if error still persists Please contact support at info@mylunacollection.com or call a representative from Luna Collection"
            );
          }
        })

        .catch((error) => {
          this.$swal.fire(
            "Order can not be proccessed. Please contact support at info@mylunacollection.com or call a representative from Luna Collection"
          );
          console.error("Error:", error);
        });
    },

    initializeBraintree() {
      braintree.client.create(
        {
          authorization: this.clientToken,
        },
        function (err, clientInstance) {
          if (err) {
            console.debug("CrediCard Component: ", { err });
            return this.displayError(err);
          }

          braintree.dataCollector.create(
            {
              client: clientInstance,
            },
            function (err, dataCollectorInstance) {
              if (err) {
                console.debug(err);
                return;
              }
              this.deviceData = dataCollectorInstance.deviceData;
            }
          );

          braintree.hostedFields.create(
            {
              client: clientInstance,
              styles: {
                input: {
                  "font-size": "19px",
                  "font-family": "helvetica, tahoma, calibri, sans-serif",
                  color: "#3a3a3a",
                },
                ":focus": {
                  color: "black",
                },
              },
              fields: {
                number: {
                  selector: "#card-number",
                  placeholder: "4111 1111 1111 1111",
                },
                cvv: {
                  selector: "#cvv",
                  placeholder: "123",
                },
                expirationDate: {
                  selector: "#expiration-date",
                  placeholder: "10/23",
                },
              },
            },
            function (err, instance) {
              if (err) {
                console.debug(err);
                return;
              }

              this.hostedFieldsInstance = instance;
            }
          );
        }
      );
    },
  },

  onBeforeUnmount() {
    if (this.hostedFieldsInstance) {
      this.hostedFieldsInstance.teardown(() => {
        this.hostedFieldsInstance = null;
        console.debug("Hosted Fields instance has been torn down.");
      });
    }
  },
};
</script>
  
  <style scoped>
.required {
  color: red;
}

._container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.loading-container {
  font-family: Arial, sans-serif;
  display: inline-block;
}

.dot {
  margin-left: 3px;
  opacity: 0; /* Start the dots as invisible */
  animation: dotAppear 1.5s infinite; /* Apply the animation */
}

.loader-text {
  opacity: 0; /* Start the dots as invisible */
  animation: dotAppear 3s infinite; /* Apply the animation */
}

.cancel-btn {
  opacity: 0.4;
  text-decoration: underline;
  font-size: 17px;
  text-transform: uppercase;
  cursor: pointer;
}

.logo {
  width: 50px;
  height: 30px;
}

@keyframes dotAppear {
  0%,
  100% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
}

/* Stagger the animations */
.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

.hosted-field {
  height: 45px !important;

  background-color: white !important;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  top: -5px;
  position: relative;
  align-items: center;
  height: 250px;
}

.input-box {
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1%;
  text-transform: capitalize;
}

.dividers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.divider {
  width: 45%;
  border-bottom: 1px solid silver;
}

.btn-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
</style>
  